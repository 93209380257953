import Swiper from 'swiper/bundle';
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'


class Slider {
    constructor(element) {
        this.element = element;
        const nextButton = element.querySelector('.js-item__references-button--next');
        const prevButton = element.querySelector('.js-item__references-button--prev');

        const slider = new Swiper('.js-item__references-swiper', {
            loop: true,
            wrapperClass: 'swiper__wrapper',
            slideClass: 'swiper__slide',
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlides: true,
            autoplay: {
                delay: 5000,
            },

            navigation: {
                nextEl: '.js-item__references-button--next',
                prevEl: '.js-item__references-button--prev',
            },

            breakpoints: {
                // when window width is >= 576px
                576: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                // when window width is >= 992px
                992: {
                    slidesPerView: 3,
                    spaceBetween: 40
                }
            }

        });
    }
}


class FullSizeGallery {
    constructor(pswpElement, parent) {
        this.pswpElement = pswpElement
        this.parent = parent
        this.open = this.open.bind(this)

        const triggers = parent.querySelectorAll('.js-swiper__trigger')
        triggers.forEach(trigger => trigger.addEventListener('click', event => this.open(event)))

        //console.log(triggers);
    }

    open(event) {
        this.getItems();
        let target = event.target;

        if (!target.classList.contains('.js-swiper__trigger')) {
            target = target.closest('.js-swiper__trigger');
        }

        const index = target.dataset.galleryIndex ? parseInt(target.dataset.galleryIndex, 10) : 1;

        const options = {
            index: index,
            loadingIndicatorDelay: 0,

            barsSize: { top: 44, bottom: 44 },
            captionEl: false,
            fullscreenEl: false,
            zoomEl: false,
            shareEl: false,

            bgOpacity: 0.8,
            showHideOpacity: true,
            showAnimationDuration: 500,
            hideAnimationDuration: 500,
        }

        const gallery = new PhotoSwipe(this.pswpElement, PhotoSwipeUI_Default, this.getItems(), options);
        gallery.init();
    }

    getItems() {
        const images = this.parent.querySelectorAll('[data-gallery-image]')
        const items = []

        images.forEach((image) => {
            items.push({
                src: image.dataset.galleryImage,
                w: image.dataset.galleryWidth,
                h: image.dataset.galleryHeight,
            })
        })
        return items
    }
}

function initFullSizeGallery() {
    const galleries = document.querySelectorAll('.js-item__references')
    const pswpElement = document.querySelector('.pswp')
    galleries.forEach(gallery => new FullSizeGallery(pswpElement, gallery))
}

document.addEventListener('DOMContentLoaded', () => {
    const slides = document.querySelector('.js-item__references');
    if (slides) {
        new Slider(slides);
    }
    initFullSizeGallery();
});


