class Selects {
    constructor(element) {
        this.el = element
        this.form = this.el.querySelector('.js-references__form')
        this.items = this.el.querySelectorAll('.js-references__item')

        this.form.addEventListener('submit', (e) => {
            e.preventDefault()
            this.filter()
        })
    }

    filter() {
        const filterData = new FormData(this.form)
        const filters = {};
        for (const formField of filterData) {
            if (formField[1]) {
                filters[formField[0]] = formField[1]
            }
        }

        for (const item of this.items) {
            let visible = true
            for (const [key, value] of Object.entries(filters)) {
                if (!item.dataset[key].split(',').includes(value)) {
                    visible = false
                    break
                }
            }
            if(visible) {
                item.classList.remove('is-hidden')
            } else {
                item.classList.add('is-hidden')
            }
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const references = document.querySelector('.js-references');
    if (references) {
        new Selects(references);
    }
});
