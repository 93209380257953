class Header {
    constructor(element) {
      this.el = element;
      this.toggler = this.el.querySelector(".js-header__toggler");
      this.menu = this.el.querySelector(".js-header__menu");
      this.menuInner = this.el.querySelector(".js-main-nav__list");
      this.togglerIcon = this.el.querySelector(".js-header__toggler-icon");
  
      this.toggler.addEventListener("click", (e) => {
        e.preventDefault();
        this.toggle();
      });
  
    }
    toggle() {
      if (this.el.classList.toggle("is-active")) {
        const height = this.menuInner.offsetHeight + "px";
        this.menu.style.maxHeight = height;
        this.togglerIcon.src = document.location.origin + "/app/themes/wp-base/assets/public/close.svg";
      } else {
        this.menu.style.removeProperty("max-height");
        this.togglerIcon.src = document.location.origin +"/app/themes/wp-base/assets/public/toggle-icon.svg";
      }
    }
  }
  
  
  document.addEventListener("DOMContentLoaded", () => {
    const header = document.querySelector(".js-header");
    if (header) {
      new Header(header);
    }
   
  })
  
  
  
  