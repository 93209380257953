import logo from '../img/logo.svg';
import close from '../img/close.svg';
import togglerIcon from '../img/toggle-icon.svg';
import carretRightIcon from '../img/caret-right-solid.svg';
import carretDownIcon from '../img/caret-down-solid.svg';
import homeIcon from '../img/home-icon.svg';
import polish from '../img/polish.png';
import english from '../img/english.png';
import online from '../img/online.png';
import stationary from '../img/stationary.png';
import attention from '../img/attention.png';
import prev from '../img/prev.png';
import next from '../img/next.png';
import facebook from '../img/facebook.png';
import linkedin from '../img/linkedin.png';
import mail from '../img/mail.png';

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
})

import './lazyloading';
import './scrollToSection';
import './fixText';
import './sticky';

import '../../../includes/views/components/molecules/full-size-gallery/full-size-gallery';

import '../../../includes/views/components/organisms/gallery/gallery';

import '../../../includes/views/layouts/header/header';

import '../../../includes/views/components/organisms/main-nav/main-nav';

import '../../../includes/views/components/organisms/services/services';

import '../../../includes/views/components/organisms/company/company';

import '../../../includes/views/components/organisms/references/references';

