class Submenu {
  constructor(element) {
    this.el = element;
    this.toggler = this.el.querySelector(".js-main-nav__toggler");
    this.submenu = this.el.querySelector(".js-submenu");
    this.submenuInner = this.el.querySelector(".js-submenu__list");
    this.parentMenu = this.el.querySelector(".js-main-nav__list");
    this.togglerIcon = this.el.querySelector(".js-main-nav__toggler-icon");

    this.toggler.addEventListener("click", (e) => {
      e.preventDefault();
      this.toggle();
    });



  }
  toggle() {
    if (this.el.classList.toggle("is-active")) {
      const height = this.submenuInner.offsetHeight;
      this.submenu.style.maxHeight = height + "px";
      const parentHeight = this.parentMenu.offsetHeight + height;
      this.el.style.maxHeight = parentHeight + "px";
      this.togglerIcon.classList.add("o-main-nav__toggler-image--rotate");
    } else {
      this.submenu.style.removeProperty("max-height");
      this.togglerIcon.classList.remove("o-main-nav__toggler-image--rotate");
    }
  }
}


document.addEventListener("DOMContentLoaded", () => {
  const submenu = document.querySelector(".js-main-nav");
  
  if (submenu) {
    new Submenu(submenu);
  }

})



