import CountUp from 'countup';

class Counter {
    constructor(element) {
        this.el = element;
        this.itemsElements = this.el.querySelectorAll(".js-counter__item");
        this.items = [];
        this.optionsDecimal = {
            decimalPlaces: 3,
            duration: 1, // animation duration in seconds (2)
            useEasing: true, // ease animation (true)
            smartEasingThreshold: 1000, // smooth easing for large numbers above this if useEasing (999)
        };
        this.options = {
            duration: 1, // animation duration in seconds (2)
            useEasing: true, // ease animation (true)
        };

        this.itemsElements.forEach((item, i) => {

            var idName = "item__countup-number--";
            var idName = idName.concat((i + 1).toString());
            var idNameWithHash = "#".concat(idName);

            var numberString = item.querySelector(".item__countup-number").textContent; // get value to be count up

            // if numberString is decimal add 3 places after dot 
            let number
            if (numberString.includes('.')) {
                number = parseFloat(numberString).toFixed(3)
            } else { // if not just parse string to number
                number = parseFloat(numberString);
            }

            const newItem = {
                el: item,
                id: item.querySelector(idNameWithHash).id,
                number: number,
            };
            this.items.push(newItem);
        });

        this.observeItem(this);
    }

    counter() {

        this.items.forEach((item, i) => {
            if (!Number.isSafeInteger(item.number)) {
                const counterDecimal = new CountUp(item.id, item.number, this.optionsDecimal);
                if (!counterDecimal.error) {
                    counterDecimal.start();
                } else {
                    console.error(counterDecimal.error);
                }
            } else {
                const counter = new CountUp(item.id, item.number, this.options);
                if (!counter.error) {
                    counter.start();
                } else {
                    console.error(counter.error);
                }
            }
        });
    }

    observeItem(thisObject) {
        var observer = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting === true) {
                thisObject.counter();
            }
        }, {threshold: [0.3]});

        observer.observe(document.querySelector(".js-counter__items"));
    }
}


document.addEventListener('DOMContentLoaded', () => {
    const counters = Array.from(document.querySelectorAll('.js-counter'));

    if (counters) {
        counters.forEach(counter => {
            new Counter(counter);
        })
    }

});
